/**
 * Created by weiyuanpan on 2/22/16.
 */
(function() {
    $(function() {
        var $csvCreateExamineesModal = $('#csvCreateExamineesModal');
        var $csvCreateExamineesTable = $csvCreateExamineesModal.find('table');

        var currentData = [];
        var columnNum = 11;  //受検者のカラム数依存

        initModal();
        initCsvFileOperation();

        function initModal() {
            $csvCreateExamineesModal.find('.modal_confirm').on('click', function() {
                //console.log(currentData);
                if (currentData.length === 0) {
                    alertModal.show('データがありません');
                    return;
                }

                queryExaminees();
                $csvCreateExamineesModal.modal('hide');
            });

            $csvCreateExamineesModal.on('hide.bs.modal', function(event) {
                resetAll();
            });
        }

        function initCsvFileOperation() {
            $('button.csv_file').on('click', function() {
                $('input#csv_file[type=file]').click();
            });

            $('input#csv_file[type=file]').on('change', onFileSelect);
        }

        /*1*/

        function queryExaminees() {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/create/examinees",
                    type: 'POST',
                    data: { 'examinees': currentData }
                })
                .done(function(data) {
                    //console.log(data);

                    //TODO check error
                    if (data['hasError']) {
                        //
                    }

                    location.reload(true);
                })
                .fail(function(data) {
                    alertModal.show('エラーが発生しました。CSVに既に登録されている受検者(メールアドレス)がないか確認してください');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        function resetAll() {
            appUtil.bt.resetTable($csvCreateExamineesTable, ['resetSearch', 'removeAll']);
            currentData = [];
            $('input#csv_file[type=file]').val('');
        }

        function onFileSelect(event) {
            var file = event.target.files[0];

            var reader = new FileReader();
            reader.onload = function(e) {
                var codes = new Uint8Array(e.target.result);
                var encoding = Encoding.detect(codes);

                // Convert encoding to unicode
                var unicodeString = Encoding.convert(codes, {
                    to: 'unicode',
                    from: encoding,
                    type: 'string'
                });

                var json = appUtil.csv.csvToJson(unicodeString);
                var data = JSON.parse(json);

                insertData(data);

                $csvCreateExamineesModal.modal('show');
            };

            reader.readAsArrayBuffer(file);
        }

        /*2*/

        function insertData(data) {
            if (data.length < 1) {
                //console.log('no data');
                return;
            }

            var rows = prepareTableRows(data);
            appUtil.bt.reLoadRows($csvCreateExamineesTable, rows);
        }

        /*3*/

        function prepareTableRows(data) {
            var rows = [];
            data.forEach(function(e, i, a) {
                if (Object.keys(e).length != columnNum) {
                    //console.log('column number is not correct');
                    rows.push({ name: 'Invalid Datum. This will not be used.' });
                    return;
                }

                if (e['name'] == '' || e['email'] == undefined) {
                    //console.log('invalid input');
                    rows.push({ name: 'Invalid Datum. This will not be used.' });
                    return;
                }

                rows.push({
                    inner_id: e['inner_id'],
                    name: e['name'],
                    name_katakana: e['name_katakana'],
                    email: e['email'],
                    gender: e['gender'],
                    birthday: e['birthday'],
                    entrance_date: e['entrance_date'],
                    department: e['department'],
                    position_id: e['position_id'],
                    policy: e['policy'],
                    remark: e['remark']
                });

                currentData.push(e);
            });
            return rows;
        }
    });
}());
