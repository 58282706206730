/**
 * Created by weiyuanpan on 2/17/16.
 */
(function() {
    $(function() {
        var $distributionDiagnosisProgramModal = $('#distributionDiagnosisProgramModal');
        var currentDiagnosisProgramId;

        var $distributionDiagnosisProgramModalTable = $distributionDiagnosisProgramModal.find('table');

        initModal();

        function initModal() {
            $distributionDiagnosisProgramModal.on('show.bs.modal', function(event) {
                var button = $(event.relatedTarget);
                initUseDiagnosisProgramSettingArea(button.data('diagnosis-program-name'), button.data('diagnosis-program-id'));
                resetExaminees();
            });

            $distributionDiagnosisProgramModal.find('.modal_confirm').on('click', function() {
                var examinees = collectCheckedExaminees();
                if (examinees.length === 0) {
                    alertModal.show('受検者を選択してください');
                    return;
                }

                queryReportPdf(examinees);
                $distributionDiagnosisProgramModal.modal('hide');
            });
        }

        /*1*/

        function initUseDiagnosisProgramSettingArea(diagnosisProgramName, diagnosisProgramId) {
            currentDiagnosisProgramId = diagnosisProgramId;
            $distributionDiagnosisProgramModal.find('#diagnosisProgramSelection option').text(diagnosisProgramName);
            $distributionDiagnosisProgramModal.find('#diagnosisProgramSelection option').val(currentDiagnosisProgramId);
        }

        function resetExaminees() {
            appUtil.bt.resetTable($distributionDiagnosisProgramModalTable, ['resetSearch', 'removeAll']);
            queryExaminees();
        }

        function collectCheckedExaminees() {
            appUtil.bt.resetTable($distributionDiagnosisProgramModalTable, ['resetSearch']);
            return appUtil.bt.getSelectedRowsIds($distributionDiagnosisProgramModalTable, 'id');
        }

        function queryReportPdf(examinees) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/distribution/examinees/" + currentDiagnosisProgramId,
                    type: 'POST',
                    data: { 'examinees': examinees }
                })
                .done(function(data) {
                    var componets = [
                        {'type': 'link', 'url': appUtil.domain + '/pdf/' + data, 'message': 'PDFを開く'},
                        {
                            'type': 'func',
                            'function':
                                "(function(){" +
                                    "var link = document.createElement('a');" +
                                    "link.href = '" + appUtil.domain + '/pdf/' + data + "';" +
                                    "link.download = '全体分布図.pdf';" +
                                    "link.click();" +
                                "})();",
                            'message': 'PDFをダウンロード'
                        }
                    ];
                    buttonModal.show(componets);

                    //console.log(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*2*/

        function queryExaminees() {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/distribution/examinees/" + currentDiagnosisProgramId
                })
                .done(function(data) {
                    //console.log(data);
                    if (data.length === 0) {
                        alertModal.show('利用できる受検者のデータがありません');
                        return;
                    }

                    insertExaminees(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*3*/

        function insertExaminees(data) {
            var rows = prepareTableRows(data);
            appUtil.bt.reLoadRows($distributionDiagnosisProgramModalTable, rows);
        }

        /*4*/

        function prepareTableRows(data) {
            var rows = [];
            //TODO add more info(current examinee, どのプログラムが最も近いかなど )
            data.forEach(function(e, i, a) {
                var gender = e['gender'] == 'male' ? '男性' : e['gender'] == 'female' ? '女性' : 'ー';
                var entrance_date = e['entrance_date'] === '1900-01-01' ? 'ー' : e['entrance_date'];
                var age = e['birthday'] === '1900-01-01' ? 'ー' : e['age'];
                rows.push({
                    inner_id: e['inner_id'],
                    name: e['name'],
                    email: e['email'],
                    gender: gender,
                    age: age,
                    entrance_date: entrance_date,
                    department: e['department'],
                    position_id: e['position']['position'],
                    last_diagnosis_answer_date: e['last_diagnosis_answer_date'],
                    id: e['id']
                });
            });
            return rows;
        }
    });
}());