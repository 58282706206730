/**
 * Created by weiyuanpan on 2/29/16.
 */
(function() {
    $(function() {
        var $birthdayDateTimePicker = $('#birthdayDateTimePicker');
        var $entranceDateDateTimePicker = $('#entranceDateDateTimePicker');


        initDateTimePicker();

        function initDateTimePicker() {
            $birthdayDateTimePicker.datetimepicker({
                format: 'YYYY-MM-DD'
            });
            $entranceDateDateTimePicker.datetimepicker({
                format: 'YYYY-MM-DD',
                useCurrent: false
            });

            $birthdayDateTimePicker.on('dp.change', function(e) {
                try {
                    $entranceDateDateTimePicker.data("DateTimePicker").minDate(e.date);
                } catch (e) {
                    alertModal.show('時間範囲設定エラー');
                }
            });

            $entranceDateDateTimePicker.on('dp.change', function(e) {
                try {
                    $birthdayDateTimePicker.data("DateTimePicker").maxDate(e.date);
                } catch (e) {
                    alertModal.show('時間範囲設定エラー');
                }
            });
        }


    });
}());