/**
 * Created by weiyuanpan on 2/28/16.
 */
(function() {
    $(function() {
        var $diagnosisProgramTable = $('#diagnosisProgramTable');
        var deleted_diagnosis_programs = [];
        var edited_diagnosis_programs = [];
        var new_diagnosis_programs = [];

        const NEW_DIAGNOSIS_PROGRAM_ID_THRESHOLD = 0;

        initTableRowOperation();
        initFormSubmit();

        function initTableRowOperation() {
            $(document).on('click', '#diagnosisProgramTable button.delete', function() {
                if (window.confirm('本当に削除しますか？')) {
                    var id = $(this).data('diagnosis-program-id');
                    //console.log(id);

                    deleteDiagnosisProgramById(id);
                } else {
                    //console.log('delete cancel');
                }

                //console.log(deleted_diagnosis_programs);
            });
        }

        function initFormSubmit() {
            $('form#program_create').submit(function() {
                prepareSendDiagnosisProgramData('form#program_create');
                return true;
            });

            $('form#program_edit').submit(function() {
                prepareSendDiagnosisProgramData('form#program_edit');
                return true;
            });

        }

        /*1*/

        function deleteDiagnosisProgramById(id) {
            if (!isNewDiagnosisProgramId(id)) {
                deleted_diagnosis_programs.push(id);
            }
            $diagnosisProgramTable.bootstrapTable('removeByUniqueId', id);
        }

        function prepareSendDiagnosisProgramData(form) {
            prepareSendDataByTableRows();
            appendDeletedDiagnosisProgramsToForm(form);
            appendEditedDiagnosisProgramsToForm(form);
            appendNewDiagnosisProgramsToForm(form);
        }

        /*2*/

        function isNewDiagnosisProgramId(id) {
            return id < NEW_DIAGNOSIS_PROGRAM_ID_THRESHOLD;
        }

        function prepareSendDataByTableRows() {
            appUtil.bt.resetTable($diagnosisProgramTable, ['resetSearch']);

            var data = $diagnosisProgramTable.bootstrapTable('getData');
            data.forEach(function(e, i, a) {
                if (isNewDiagnosisProgramId(e['id'])) {
                    new_diagnosis_programs.push(e);
                } else {
                    if (e['edited'] == 'yes') {
                        edited_diagnosis_programs.push(e);
                    }
                }
            });
        }

        function appendDeletedDiagnosisProgramsToForm(form) {
            appendHiddenInputToForm(form, 'deleted_diagnosis_programs', deleted_diagnosis_programs);
        }

        function appendEditedDiagnosisProgramsToForm(form) {
            appendHiddenInputToForm(form, 'edited_diagnosis_programs', edited_diagnosis_programs);
        }

        function appendNewDiagnosisProgramsToForm(form) {
            appendHiddenInputToForm(form, 'new_diagnosis_programs', new_diagnosis_programs);
        }

        /*3*/

        function appendHiddenInputToForm(form, name, value) {
            $('<input />').attr('type', 'hidden')
                .attr('name', name)
                .attr('value', JSON.stringify(value))
                .appendTo(form);
        }
    });
}());

function diagnosisProgramActionFormatter(value, row, index) {
    if (row['status'] != 'finished') {
        return [
            '<button type="button" class="btn btn-default delete" data-diagnosis-program-id="' + row['id'] + '">' + translate('delete') + '</button>',
            '<button type="button" class="btn btn-default edit" data-toggle="modal" data-target="#diagnosisModal" data-diagnosis-program-id="' + row['id'] + '">' + translate('examineesIndexEdit') + '</button>'
        ].join('');
    } else {
        return '';
    }
}

window.diagnosisProgramActionEvents = {
    //'click .delete': function (e, value, row, index) {
    //    console.log(value, row, index);
    //},
    //'click .edit': function (e, value, row, index) {
    //    console.log(value, row, index);
    //}
};

function translate(value) {
    switch (value) {
        case 'delete':
            return '削除';
            break;
        case 'examineesIndexEdit':
            return '編集';
            break;
    }
}
