/**
 * Created by weiyuanpan on 2/23/16.
 */
(function() {
    $(function() {
        //TODO use api to get all examinees(now just use the same variable of index page)

        var $passwordDownloadModal =  $('#passwordDownloadModal');
        var $passwordDownloadModalTable = $passwordDownloadModal.find('table');

        initModal();

        function initModal() {
            $passwordDownloadModal.on('show.bs.modal', function(event) {
                appUtil.bt.resetTable($passwordDownloadModalTable, ['resetSearch', 'uncheckAll']);
                $passwordDownloadModalTable.find('tbody tr').removeClass('selected');
            });

            $passwordDownloadModal.find('.modal_confirm').on('click', function() {
                var examinees = collectCheckedExaminees();

                if (examinees.length === 0) {
                    alertModal.show('受検者を選択してください');
                    return;
                }

                queryPassword(examinees);

                $passwordDownloadModal.modal('hide');
            });
        }

        /*1*/

        function collectCheckedExaminees() {
            appUtil.bt.resetTable($passwordDownloadModalTable, ['resetSearch']);
            return appUtil.bt.getSelectedRowsIds($passwordDownloadModalTable, 'id');
        }

        function queryPassword(examinees) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/download/password",
                    type: 'POST',
                    data: { 'examinees': examinees }
                })
                .done(function(data) {
                    if (data['hasError']) {
                        alertModal.show('Error');
                        return;
                    }

                    var link = document.createElement('a');
                    link.href = appUtil.domain + '/csv/' + data['filename'];
                    //link.download = 'file.csv';
                    link.click();

                    //console.log(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }
    });
}());