/**
 * Created by weiyuanpan on 2/28/16.
 */
(function() {
    $(function() {
        var $diagnosisModal = $('#diagnosisModal');
        var $diagnosisProgramStartDateTimePicker = $('#diagnosisProgramStartDateTimePicker');
        var $diagnosisProgramEndDateTimePicker = $('#diagnosisProgramEndDateTimePicker');
        var diagnosisCourse1stVal = $diagnosisModal.find('select[name=diagnosisCourse] option').val();
        var diagnosisProgramLatestEndDate;

        var editClicked = false;

        var $diagnosisProgramTable = $('#diagnosisProgramTable');
        var currentDiagnosisProgramId;
        var currentNewDiagnosisProgramId = -1;

        initDateTimePicker();
        initModal();

        if (!$diagnosisModal.find("input[name='diagnosisProgramAppReportItem[]'][value='ability']").prop('checked')) {
            $.each($diagnosisModal.find("input[name='AbilityAppReportItem[]']"), function (idx, element) {
                $(element).prop('checked', false);
                $(element).attr('disabled', 'disabled');
            })
        }

        $diagnosisModal.find("input[name='diagnosisProgramAppReportItem[]'][value='ability']").on('change', function () {
            if ($(this).prop('checked')) {
                $.each($diagnosisModal.find("input[name='AbilityAppReportItem[]']"), function (idx, element) {
                    $(element).prop('checked', true);
                    $(element).removeAttr('disabled');
                })
            } else {
                $.each($diagnosisModal.find("input[name='AbilityAppReportItem[]']"), function (idx, element) {
                    $(element).prop('checked', false);
                    $(element).attr('disabled', 'disabled');
                })
            }
        });

        $diagnosisModal.find("input[name='AbilityAppReportItem[]']").on('change', function () {
            var checked = $("input[name='AbilityAppReportItem[]']").filter(':checked');
            if (checked.length == 0) {
                $diagnosisModal.find("input[name='diagnosisProgramAppReportItem[]'][value='ability']").prop('checked', false);

                $.each($diagnosisModal.find("input[name='AbilityAppReportItem[]']"), function (idx, element) {
                    $(element).attr('disabled', 'disabled');
                })
            }
        });


        function initDateTimePicker() {
            $diagnosisProgramStartDateTimePicker.datetimepicker();
            $diagnosisProgramEndDateTimePicker.datetimepicker({
                useCurrent: false
            });

            $diagnosisProgramStartDateTimePicker.on('dp.change', function(e) {
                try {
                    $diagnosisProgramEndDateTimePicker.data("DateTimePicker").minDate(e.date);
                } catch (e) {
                    alertModal.show('時間範囲設定エラー');
                }
            });

            $diagnosisProgramEndDateTimePicker.on('dp.change', function(e) {
                try {
                    $diagnosisProgramStartDateTimePicker.data("DateTimePicker").maxDate(e.date);
                } catch (e) {
                    alertModal.show('時間範囲設定エラー');
                }
            });
        }

        function initModal() {
            $diagnosisModal.on('show.bs.modal', function(event) {
                appUtil.bt.resetTable($diagnosisProgramTable, ['resetSearch']);

                var button = $(event.relatedTarget);
                if (button.hasClass('edit')) {
                    editClicked = true;
                    var id = button.data('diagnosis-program-id');
                    currentDiagnosisProgramId = id;
                    resetDiagnosisProgramSettingToEdit(id);
                    return;
                }

                currentDiagnosisProgramId = currentNewDiagnosisProgramId;
                currentNewDiagnosisProgramId--;
                resetDiagnosisProgramSettingToCreate();
            });

            $diagnosisModal.find('.modal_confirm').on('click', function() {
                var row = generateTableRowByModalSetting();
                if (row == 'invalid') {
                    return;
                }

                if (editClicked) {
                    $diagnosisProgramTable.bootstrapTable('updateByUniqueId', { id: currentDiagnosisProgramId, row: row});
                } else {
                    $diagnosisProgramTable.bootstrapTable('append', [row]);
                }

                $diagnosisModal.modal('hide');
            });

            $diagnosisModal.on('hide.bs.modal', function(event) {
                if (editClicked) {
                    editClicked = false;
                }
                unsetDateTimePicker();
            });
        }

        /*1*/

        function resetDiagnosisProgramSettingToEdit(id) {
            var row = $diagnosisProgramTable.bootstrapTable('getRowByUniqueId', id);
            $diagnosisModal.find('#diagnosisProgramName').val(row['name']);
            $diagnosisModal.find('select[name=diagnosisCourse]').val(row['diagnosis_id']);
            resetDateTimePickerToEditDiagnosisProgram(row);
            resetAppReportItemsToEditDiagnosisProgram(row);
        }

        function resetDiagnosisProgramSettingToCreate() {
            $diagnosisModal.find('#diagnosisProgramName').val('');
            $diagnosisModal.find('select[name=diagnosisCourse]').val(diagnosisCourse1stVal);
            resetDateTimePickerToCreateDiagnosisProgram();
            $diagnosisModal.find("input[name='diagnosisProgramAppReportItem[]']").prop('checked', false);
        }

        function generateTableRowByModalSetting() {
            var row = {};

            row.name = $diagnosisModal.find('#diagnosisProgramName').val();
            if (row.name == '') {
                alertModal.show('名前を記入してください');
                return 'invalid';
            }

            row.start_date = $diagnosisProgramStartDateTimePicker.data("DateTimePicker").date();
            if (row.start_date == null) {
                alertModal.show('開始時間を選んでください');
                return 'invalid';
            }

            row.end_date = $diagnosisProgramEndDateTimePicker.data("DateTimePicker").date();
            if (row.end_date == null) {
                alertModal.show('終了時間を選んでください');
                return 'invalid';

            }

            var now = moment();
            row.status = now.isBefore(row.start_date) ? 'inactive' : now.isBefore(row.end_date) ? 'active' : 'finished';
            if (row.status == 'inactive') {
                //TODO {{ trans('main.programWaitStart') }}
                row.status_html = '<span class="label label-danger">' + translate('programWaitStart') + '</span>';
            } else if (row.status == 'active') {
                row.status_html = '<span class="label label-success">' + translate('programInProgress') + '</span>';
            } else if (row.status == 'finished') {
                row.status_html = '<span class="label label-default">' + translate('programEnd') + '</span>';
            } else {
                row.status_html ='';
            }

            row.app_report_items = {};
            row.app_report_items_html = '';
            $.each($diagnosisModal.find("input[name='diagnosisProgramAppReportItem[]']"), function(i, e) {
                var $item = $(e);
                var value = $item.val();

                if ($item.prop('checked')) {
                    row.app_report_items[value] = 1;
                    row.app_report_items_html += '<p>' + translate(value) + '</p>';
                    
                    if (value == 'ability') {
                        row.app_report_items['ability_items'] = {};

                        $.each($diagnosisModal.find("input[name='AbilityAppReportItem[]']"), function (idx, element) {
                            var $abilityItem = $(element);
                            var abilityValue = $abilityItem.val();

                            if ($abilityItem.prop('checked')) {
                                row.app_report_items['ability_items'][abilityValue] = 1;
                            } else {
                                row.app_report_items['ability_items'][abilityValue] = 0;
                            }
                        })
                    }
                } else {
                    row.app_report_items[value] = 0;
                }
            });
            row.app_report_items = JSON.stringify(row.app_report_items);

            row.diagnosis_id = $diagnosisModal.find('select[name=diagnosisCourse]').val();

            row.id = currentDiagnosisProgramId;

            row.edited = 'yes';

            row.start_date = row.start_date.format("YYYY-MM-DD HH:mm:ss");
            row.end_date = row.end_date.format("YYYY-MM-DD HH:mm:ss");

            //console.log(row);
            return row;
        }

        function unsetDateTimePicker() {
            $diagnosisProgramStartDateTimePicker.data("DateTimePicker").minDate(false);
            $diagnosisProgramStartDateTimePicker.data("DateTimePicker").maxDate(false);

            $diagnosisProgramEndDateTimePicker.data("DateTimePicker").minDate(false);
            $diagnosisProgramEndDateTimePicker.data("DateTimePicker").maxDate(false);

            $diagnosisProgramStartDateTimePicker.data("DateTimePicker").date(null);
            $diagnosisProgramEndDateTimePicker.data("DateTimePicker").date(null);
        }

        /*2*/

        function resetDateTimePickerToEditDiagnosisProgram(row) {
            var edit_start_date = moment(row['start_date']);
            var edit_end_date = moment(row['end_date']);

            var now = moment();
            var start_min_date = now.isBefore(edit_start_date) ? now : edit_start_date;
            var end_max_date = moment(9999, 'YYYY');

            var data = $diagnosisProgramTable.bootstrapTable('getData');
            data.forEach(function(e, i, a) {
                if (e['id'] == row['id']) {
                    return;
                }

                var start_date = moment(e['start_date']);
                var end_date = moment(e['end_date']);

                if (start_date.isBefore(edit_start_date)) {
                    if (end_date.isAfter(start_min_date)) {
                        start_min_date = end_date;
                    }
                } else {
                    if (start_date.isBefore(end_max_date)) {
                        end_max_date = start_date;
                    }
                }
            });

            try {
                $diagnosisProgramStartDateTimePicker.data("DateTimePicker").maxDate(false);
                $diagnosisProgramEndDateTimePicker.data("DateTimePicker").minDate(false);

                $diagnosisProgramStartDateTimePicker.data("DateTimePicker").minDate(start_min_date);
                $diagnosisProgramEndDateTimePicker.data("DateTimePicker").maxDate(end_max_date);

                $diagnosisProgramStartDateTimePicker.data("DateTimePicker").date(edit_start_date);
                $diagnosisProgramEndDateTimePicker.data("DateTimePicker").minDate(edit_start_date);

                $diagnosisProgramEndDateTimePicker.data("DateTimePicker").date(edit_end_date);
                $diagnosisProgramStartDateTimePicker.data("DateTimePicker").maxDate(edit_end_date);
            } catch (e) {
                alertModal.show('時間範囲設定エラー');
            }
        }

        function resetAppReportItemsToEditDiagnosisProgram(row) {
            var app_report_items = JSON.parse(row['app_report_items']);
            $.each($diagnosisModal.find("input[name='diagnosisProgramAppReportItem[]']"), function(i, e) {
                var $item = $(e);
                var value = $item.val();

                if (app_report_items[value] == 1) {
                    $item.prop('checked', true);

                    if (value == 'ability') {
                        $.each($diagnosisModal.find("input[name='AbilityAppReportItem[]']"), function (idx, element) {
                            var $abilityItem = $(element);
                            var abilityValue = $abilityItem.val();

                            if (app_report_items['ability_items'] && app_report_items['ability_items'][abilityValue] == 1) {
                                $abilityItem.prop('checked', true);
                            } else {
                                $abilityItem.prop('checked', false);
                            }
                            $abilityItem.removeAttr('disabled');
                        })
                    }
                } else {
                    $item.prop('checked', false);

                    if (value == 'ability') {
                        $.each($diagnosisModal.find("input[name='AbilityAppReportItem[]']"), function (idx, element) {
                            var $abilityItem = $(element);
                            $abilityItem.prop('checked', false);
                            $abilityItem.attr('disabled', 'disabled');
                        })
                    }
                }
            });
        }

        function resetDateTimePickerToCreateDiagnosisProgram() {
            $diagnosisProgramEndDateTimePicker.data("DateTimePicker").date(null);
            updateDiagnosisProgramLatestEndDate();
            try {
                $diagnosisProgramStartDateTimePicker.data("DateTimePicker").minDate(diagnosisProgramLatestEndDate);
            } catch (e) {
                alertModal.show('時間範囲設定エラー');
            }
            $diagnosisProgramStartDateTimePicker.data("DateTimePicker").date(diagnosisProgramLatestEndDate);
        }

        function translate(value) {
            switch (value) {
                case 'behaviour':
                    return 'ビヘイビアレポート';
                    break;
                case 'ability':
                    return 'アビリティレポート';
                    break;
                case 'derailment':
                    return 'ディレールメント';
                    break;
                case 'salesConsulting':
                    return 'セールス＆コンサルティング';
                    break;
                case 'letter':
                    return '自己成長促進Letter';
                    break;
                case 'stress':
                    return 'ストレス状態＆耐性';
                    break;
                case 'programWaitStart':
                    return '実施前';
                    break;
                case 'programInProgress':
                    return '実施中';
                    break;
                case 'programEnd':
                    return '終了';
                    break;
            }
        }

        /*3*/

        function updateDiagnosisProgramLatestEndDate() {
            diagnosisProgramLatestEndDate = moment();

            var data = $diagnosisProgramTable.bootstrapTable('getData');
            data.forEach(function(e, i, a) {
                var end_date = moment(e['end_date']);
                if (diagnosisProgramLatestEndDate.isBefore(end_date)) {
                    diagnosisProgramLatestEndDate = end_date;
                }
            });
        }
    });
}());