/**
 * Created by weiyuanpan on 2/24/16.
 */
var AppUtil = function() {

    this.domain = window.location.protocol + '//' + window.location.host;

    this.csv = new CsvUtil();
    this.bt = new BootstrapTableUtil();
};

var BootstrapTableUtil = function() {
    this.resetTable = function($table, resetWorks) {
        for (var i = 0; i < resetWorks.length; i++) {
            $table.bootstrapTable(resetWorks[i]);
        }
    };

    //すべてのrowsに対して操作するとき、reset searchやpaginationなど気をつけること
    this.checkSelectedRows = function($table) {
        checkRowsBy($table, 'selected', ['yes']);
    };

    //すべてのrowsに対して操作するとき、reset searchやpaginationなど気をつけること
    this.syncSelectResults = function($table, name) {
        manipulateByCheckedIndex($table, name,
            updateCellByIndex, {field: 'selected', value: 'yes'});

        manipulateByUncheckedIndex($table, name,
            updateCellByIndex, {field: 'selected', value: 'no'});
    };

    this.reLoadRows = function($table, rows) {
      $table.bootstrapTable('load', rows);
    };

    //getAllSelectionsを利用しているので、reset searchやpaginationなど気にしなくて良い
    this.getSelectedRowsIds = function($table, field) {
        var selectedRows = $table.bootstrapTable('getAllSelections');
        var ids = [];
        selectedRows.forEach(function(e, i, a) {
            ids.push(e[field]);
        });
        return ids;
    };
    
    var manipulateByCheckedIndex = function($table, name, fn, args) {
        $table.find('tbody tr td input[name="' + name + '"]:checked').each(function(i, e) {
            fn($table, $(e).data('index'), args);
        });
    };

    var manipulateByUncheckedIndex = function($table, name, fn, args) {
        $table.find('tbody tr td input[name="' + name + '"]:not(:checked)').each(function(i, e) {
            fn($table, $(e).data('index'), args);
        });
    };

    var updateCellByIndex = function($table, index, args) {
        $table.bootstrapTable('updateCell', {
            index: index,
            field: args.field,
            value: args.value,
            reinit: false
        });
    };

    var checkRowsBy = function($table, field, values) {
        $table.bootstrapTable('checkBy', {
            field: field,
            values: values
        });
    }
};

var CsvUtil = function() {
    this.csvToJson = function(csv) {
        var array = this.csvToArray(csv);
        var objArray = [];
        for (var i = 1; i < array.length; i++) {
            if (array[i].length == 1 && array[i] == '') {
                break;
            }
            objArray[i - 1] = {};
            for (var k = 0; k < array[0].length && k < array[i].length; k++) {
                var key = array[0][k];
                objArray[i - 1][key] = array[i][k]
            }
        }

        var json = JSON.stringify(objArray);
        var str = json.replace(/},/g, "},\r\n");

        return str;
    };

    this.csvToArray = function(strData, strDelimiter) {
        strDelimiter = (strDelimiter || ",");
        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp((
            // Delimiters.
        "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
            // Quoted fields.
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
            // Standard fields.
        "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
        // Create an array to hold our data. Give the array
        // a default empty first row.
        var arrData = [[]];
        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;
        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while (arrMatches = objPattern.exec(strData)) {
            // Get the delimiter that was found.
            var strMatchedDelimiter = arrMatches[1];
            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push([]);
            }
            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[2]) {
                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                var strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"), "\"");
            } else {
                // We found a non-quoted value.
                var strMatchedValue = arrMatches[3];
            }
            // Now that we have our value string, let's add
            // it to the data array.
            arrData[arrData.length - 1].push(strMatchedValue);
        }
        // Return the parsed data.
        return (arrData);
    };
};

var appUtil = new AppUtil();