/**
 * Created by weiyuanpan on 2/10/16.
 */
(function() {
    $(function() {
        var $examineeListModal =  $('#examineeListModal');
        var $examineeListModalTable = $examineeListModal.find('table');
        var $examineeListModalButtonSpan = $('button[data-target="#examineeListModal"]').find('span');

        appUtil.bt.checkSelectedRows($examineeListModalTable);

        initModal();

        function initModal() {
            $examineeListModal.on('show.bs.modal', function(event) {
                appUtil.bt.resetTable($examineeListModalTable, ['resetSearch', 'uncheckAll']);
                appUtil.bt.checkSelectedRows($examineeListModalTable);
            });

            $examineeListModal.find('.modal_confirm').on('click', function() {
                appUtil.bt.resetTable($examineeListModalTable, ['resetSearch']);
                appUtil.bt.syncSelectResults($examineeListModalTable, 'examinee_id[]');
                updateModalButtonNumber();
                $examineeListModal.modal('hide');
            });
        }

        function updateModalButtonNumber() {
            var num = $examineeListModalTable.bootstrapTable('getAllSelections').length;
            if (num == 0) {
                $examineeListModalButtonSpan.text('');
            } else {
                $examineeListModalButtonSpan.text('(' + num + ')');
            }
        }
    });
}());