/**
 * Created by weiyuanpan on 2/17/16.
 */
(function() {
    $(function() {
        var $distributionExamineeModal = $('#distributionExamineeModal');
        var $diagnosisProgramSelection =  $distributionExamineeModal.find('select[name=diagnosisProgramSelection]');
        var diagnosisProgram1stVal = $distributionExamineeModal.find('select[name=diagnosisProgramSelection] option').val();

        var currentDiagnosisProgramId;
        var currentExamineeId;
        var currentExamineeName;
        var currentExamineeHasAnswer;

        var $distributionExamineeModalTable = $distributionExamineeModal.find('table');

        initModal();

        function initModal() {
            initSettingChangeEvent();

            $distributionExamineeModal.on('show.bs.modal', function(event) {
                var button = $(event.relatedTarget);
                currentExamineeId = button.data('examinee-id');
                currentExamineeName = button.data('examinee-name');

                initUseDiagnosisProgramSettingArea();
                resetExaminees();
            });

            $distributionExamineeModal.find('.modal_confirm').on('click', function() {
                if (!currentExamineeHasAnswer) {
                    alertModal.show('選択した診断にまだ回答していません');
                    return;
                }

                if (currentDiagnosisProgramId == undefined) {
                    alertModal.show('診断項目がありません');
                    return;
                }

                var examinees = collectCheckedExaminees();
                queryReportPdf(examinees);
                $distributionExamineeModal.modal('hide');
            });
        }

        /*1*/

        function initSettingChangeEvent() {
            $diagnosisProgramSelection.change(function() {
                currentDiagnosisProgramId = $diagnosisProgramSelection.val();

                resetExaminees();
            });
        }

        function initUseDiagnosisProgramSettingArea() {
            $diagnosisProgramSelection.val(diagnosisProgram1stVal);
            currentDiagnosisProgramId = diagnosisProgram1stVal;
        }

        function resetExaminees() {
            appUtil.bt.resetTable($distributionExamineeModalTable, ['resetSearch', 'removeAll']);
            if (currentDiagnosisProgramId == undefined) {
                alertModal.show('診断項目がありません');
                return;
            }
            queryExaminees();
        }

        function collectCheckedExaminees() {
            appUtil.bt.resetTable($distributionExamineeModalTable, ['resetSearch']);
            return appUtil.bt.getSelectedRowsIds($distributionExamineeModalTable, 'id');
        }

        function queryReportPdf(examinees) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/distribution/examinee/" + currentExamineeId + '/' + currentDiagnosisProgramId,
                    type: 'POST',
                    data: { 'examinees': examinees }
                })
                .done(function(data) {
                    var componets = [
                        {'type': 'link', 'url': appUtil.domain + '/pdf/' + data, 'message': 'PDFを開く'},
                        {
                            'type': 'func',
                            'function':
                                "(function(){" +
                                    "var link = document.createElement('a');" +
                                    "link.href = '" + appUtil.domain + '/pdf/' + data + "';" +
                                    "link.download = '" + currentExamineeName + ".pdf';" +
                                    "link.click();" +
                                "})();",
                            'message': 'PDFをダウンロード'
                        }
                    ];
                    buttonModal.show(componets);

                    //console.log(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*2*/

        function queryExaminees() {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/distribution/examinees/" + currentDiagnosisProgramId
                })
                .done(function(data) {
                    //console.log(data);

                    currentExamineeHasAnswer = false;

                    if (data.length === 0) {
                        alertModal.show('利用できる受検者のデータがありません');
                        return;
                    }

                    checkCurrentExamineeAnswerExistence(data);

                    if (!currentExamineeHasAnswer) {
                        alertModal.show('この受検者の回答がありません');
                        return;
                    }

                    insertExaminees(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*3*/

        function checkCurrentExamineeAnswerExistence(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i]['id'] == currentExamineeId) {
                    currentExamineeHasAnswer = true;
                    data.splice(i, 1);
                    break;
                }
            }
        }

        function insertExaminees(data) {
            var rows = prepareTableRows(data);
            appUtil.bt.reLoadRows($distributionExamineeModalTable, rows);
        }

        /*4*/

        function prepareTableRows(data) {
            var rows = [];
            //TODO add more info(current examinee, どのプログラムが最も近いかなど )
            data.forEach(function(e, i, a) {
                var gender = e['gender'] == 'male' ? '男性' : e['gender'] == 'female' ? '女性' : 'ー';
                var entrance_date = e['entrance_date'] === '1900-01-01' ? 'ー' : e['entrance_date'];
                var age = e['birthday'] === '1900-01-01' ? 'ー' : e['age'];
                rows.push({
                    inner_id: e['inner_id'],
                    name: e['name'],
                    email: e['email'],
                    gender: gender,
                    age: age,
                    entrance_date: entrance_date,
                    department: e['department'],
                    position_id: e['position']['position'],
                    last_diagnosis_answer_date: e['last_diagnosis_answer_date'],
                    id: e['id']
                });
            });
            return rows;
        }
    });
}());