/**
 * Created by KazukiSugita on 2016/02/22.
 */

function LoadingModal() {
    var modal = $("#loadingModal");
    this.start = function() {
        modal.css('display','block');
    };
    this.stop = function() {
        modal.css('display','none');
    };
}
var loadingModal = new LoadingModal();

function AlertModal() {
    var modal = '#alertModal';
    this.show = function(str) {
        $(modal+' .content').prepend('<p>'+str+'</p>');
        $(modal).css('display','block');
    };
    this.hide = function() {
        $(modal+' .content p').remove();
        $(modal).css('display','none');
    };
}
var alertModal = new AlertModal();

function LinkModal() {
    var modal = '#linkModal';
    this.show = function(url) {
        $(modal+' .content').prepend('<a href="' + url + '" target="_blank">PDFを開く</a>');
        $(modal).css('display','block');
    };
    this.hide = function() {
        $(modal+' .content a').remove();
        $(modal).css('display','none');
    };
}
var linkModal = new LinkModal();

function ButtonModal() {
    var modal = '#buttonModal';
    this.show = function(components) {
        components.forEach(function(e, i, a) {
            switch (e['type']) {
                case 'link':
                    $(modal + ' .content').prepend('<button onclick="window.open(\'' + e['url'] + '\')" class="btn btn-default">' + e['message'] + '</button>');
                    break;
                case 'func':
                    $(modal+' .content').prepend('<button onclick="'+ e['function'] + '" class="btn btn-default">' + e['message'] + '</button>');
                    break;
            }
        });
        $(modal).css('display','block');
    };

    this.hide = function() {
        $(modal+' .content button:not(.close)').remove();
        $(modal).css('display','none');
    };
}
var buttonModal = new ButtonModal();