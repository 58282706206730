/**
 * Created by weiyuanpan on 2/9/16.
 */
(function() {
    $(function() {
        var $programListModal =  $('#programListModal');
        var $programListModalTable = $programListModal.find('table');
        var $programListModalButtonSpan = $('button[data-target="#programListModal"]').find('span');

        appUtil.bt.checkSelectedRows($programListModalTable);

        initModal();

        function initModal() {
            $programListModal.on('show.bs.modal', function(event) {
                appUtil.bt.resetTable($programListModalTable, ['resetSearch', 'uncheckAll']);
                appUtil.bt.checkSelectedRows($programListModalTable);
            });

            $programListModal.find('.modal_confirm').on('click', function() {
                appUtil.bt.resetTable($programListModalTable, ['resetSearch']);
                appUtil.bt.syncSelectResults($programListModalTable, 'program_id[]');
                updateModalButtonNumber();
                $programListModal.modal('hide');
            });
        }

        function updateModalButtonNumber() {
            var num = $programListModalTable.bootstrapTable('getAllSelections').length;
            if (num == 0) {
                $programListModalButtonSpan.text('');
            } else {
                $programListModalButtonSpan.text('(' + num + ')');
            }
        }
    });
}());