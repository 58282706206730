/**
 * Created by weiyuanpan on 2/28/16.
 */
(function() {
    //$(function() {
    //
    //});

    //var $questionnaireModal = $('#questionnaireModal');
    //var $programQuestionnaireStartDateTimePicker = $('#programQuestionnaireStartDateTimePicker');
    //var $programQuestionnaireEndDateTimePicker = $('#programQuestionnaireEndDateTimePicker');
    //var questionnaireCourse1stVal = $questionnaireModal.find('select[name=questionnaireCourse] option').val();
    //var programQuestionnaireLatestEndDate;

    //var editClicked = false;

    //$questionnaireModal.find('.modal_confirm').on('click', function() {
    //    //TODO validation check
    //    //name is not over 256
    //
    //    var name = $questionnaireModal.find('#programQuestionnaireName').val();
    //    if (name == '') {
    //        alertModal.show('名前を記入するしてください');
    //        return;
    //    }
    //    var questionnaire_id = $questionnaireModal.find('select[name=questionnaireCourse]').val();
    //    var start_date = $programQuestionnaireStartDateTimePicker.data("DateTimePicker").date();
    //    if (start_date == null) {
    //        alertModal.show('開始時間を選んでください');
    //        return;
    //    }
    //    var end_date = $programQuestionnaireEndDateTimePicker.data("DateTimePicker").date();
    //    if (end_date == null) {
    //        alertModal.show('終了時間を選んでください');
    //        return;
    //    }
    //    var now = moment();
    //    var status = now.isBefore(start_date) ? 'inactive' : now.isBefore(end_date) ? 'active' : 'finished';
    //    var statusHtml;
    //    if (status == 'inactive') {
    //        //TODO {{ trans('main.programWaitStart') }}
    //        statusHtml = '<td><span class="label label-danger">実施前</span></td>';
    //    } else if (status == 'active') {
    //        statusHtml = '<td><span class="label label-success">実施中</span></td>';
    //    } else if (status == 'finished') {
    //        statusHtml = '<td><span class="label label-default">終了</span></td>';
    //    } else {
    //        statusHtml ='<td></td>';
    //    }
    //
    //    var input_json;
    //    var data_program_questionnaire_id = '';
    //    if (editClicked) {
    //        var json_str = $('tbody#added_programQuestionnaires tr.editing').find('input[type=hidden]').val();
    //        var json_obj = JSON.parse(json_str);
    //        json_obj['name'] = name;
    //        json_obj['start_date'] = start_date.format("YYYY-MM-DD HH:mm:ss");
    //        json_obj['end_date'] = end_date.format("YYYY-MM-DD HH:mm:ss");
    //        json_obj['status'] = status;
    //        json_obj['questionnaire_id'] = questionnaire_id;
    //        input_json = JSON.stringify(json_obj);
    //
    //        data_program_questionnaire_id = ' data-program-questionnaire-id="' + json_obj['id'] + '"';
    //    } else {
    //        input_json = JSON.stringify({
    //            "name": name,
    //            "start_date": start_date.format("YYYY-MM-DD HH:mm:ss"), //formatを利用しないとUTC時間になってしまう
    //            "end_date": end_date.format("YYYY-MM-DD HH:mm:ss"),
    //            "status": status,
    //            "questionnaire_id": questionnaire_id
    //        });
    //    }
    //
    //    var actionHtml = status == 'finished' ? '' :
    //    '<td>' +
    //        '<div class="btn-group">' +
    //        //TODO status終了後のオフ
    //
    //            //TODO {{ trans('main.delete') }}, {{ trans('main.examineesIndexEdit') }}
    //            '<button type="button" class="btn btn-default delete"' + data_program_questionnaire_id + '>削除</button>' +
    //            '<button type="button" class="btn btn-default edit" data-toggle="modal" data-target="#questionnaireModal">編集</button>' +
    //        '</div>' +
    //    '</td>';
    //
    //    var html =
    //        '<tr>' +
    //            '<td>' + name + '</td>' +
    //            '<td>' + start_date.format("YYYY-MM-DD HH:mm:ss") + '</td>' +
    //            '<td>' + end_date.format("YYYY-MM-DD HH:mm:ss") + '</td>' +
    //            statusHtml +
    //            actionHtml +
    //            "<input type='hidden' name='programQuestionnaires[]' value='" + input_json + "'>" +
    //        '</tr>';
    //
    //    if (editClicked) {
    //        $('tbody#added_programQuestionnaires tr.editing').replaceWith(html);
    //    } else {
    //        $('tbody#added_programQuestionnaires').append(html);
    //    }
    //
    //    $questionnaireModal.modal('hide');
    //});

    //$questionnaireModal.on('show.bs.modal', function(event) {
    //
    //    var button = $(event.relatedTarget);
    //    if (button.hasClass('edit')) {
    //        editClicked = true;
    //
    //        button.closest('tr').toggleClass('editing', true);
    //        var edit_json_str = button.closest('tr').find('input[type=hidden]').val();
    //        var edit_programQuestionnaire = JSON.parse(edit_json_str);
    //        $questionnaireModal.find('#programQuestionnaireName').val(edit_programQuestionnaire['name']);
    //        $questionnaireModal.find('select[name=questionnaireCourse]').val(edit_programQuestionnaire['questionnaire_id']);
    //
    //        //TODO
    //        var edit_start_date = moment(edit_programQuestionnaire.start_date);
    //        var edit_end_date = moment(edit_programQuestionnaire.end_date);
    //
    //        var now = moment();
    //        var start_min_date = now.isBefore(edit_start_date) ? now : edit_start_date;
    //        var end_max_date = moment(9999, 'YYYY');
    //
    //        var $added_programQuestionnaires = $('tbody#added_programQuestionnaires').find('tr');
    //        for (var i = 0; i < $added_programQuestionnaires.length; i++) {
    //            if ($($added_programQuestionnaires[i]).hasClass('editing')) {
    //                continue;
    //            }
    //            var json_str = $($added_programQuestionnaires[i]).find('input[type=hidden]').val();
    //            var programQuestionnaire = JSON.parse(json_str);
    //            var start_date = moment(programQuestionnaire.start_date);
    //            var end_date = moment(programQuestionnaire.end_date);
    //
    //            if (start_date.isBefore(edit_start_date)) {
    //                if (end_date.isAfter(start_min_date)) {
    //                    start_min_date = end_date;
    //                }
    //            } else {
    //                if (start_date.isBefore(end_max_date)) {
    //                    end_max_date = start_date;
    //                }
    //            }
    //        }
    //
    //        $programQuestionnaireStartDateTimePicker.data("DateTimePicker").maxDate(false);
    //        $programQuestionnaireEndDateTimePicker.data("DateTimePicker").minDate(false);
    //
    //        $programQuestionnaireStartDateTimePicker.data("DateTimePicker").minDate(start_min_date);
    //        $programQuestionnaireEndDateTimePicker.data("DateTimePicker").maxDate(end_max_date);
    //
    //        $programQuestionnaireStartDateTimePicker.data("DateTimePicker").date(edit_start_date);
    //        $programQuestionnaireEndDateTimePicker.data("DateTimePicker").minDate(edit_start_date);
    //
    //        $programQuestionnaireEndDateTimePicker.data("DateTimePicker").date(edit_end_date);
    //        $programQuestionnaireStartDateTimePicker.data("DateTimePicker").maxDate(edit_end_date);
    //
    //        return;
    //    }
    //
    //    $questionnaireModal.find('#programQuestionnaireName').val('');
    //    $questionnaireModal.find('select[name=questionnaireCourse]').val(questionnaireCourse1stVal);
    //    $programQuestionnaireEndDateTimePicker.data("DateTimePicker").date(null);
    //
    //    programQuestionnaireLatestEndDate = moment();
    //    var $added_programQuestionnaires = $('tbody#added_programQuestionnaires').find('tr');
    //    for (var i = 0; i < $added_programQuestionnaires.length; i++) {
    //        var json_str = $($added_programQuestionnaires[i]).find('input[type=hidden]').val();
    //        var programQuestionnaire = JSON.parse(json_str);
    //        var end_date = moment(programQuestionnaire.end_date);
    //        if (programQuestionnaireLatestEndDate.isBefore(end_date)) {
    //            programQuestionnaireLatestEndDate = end_date;
    //        }
    //    }
    //    $programQuestionnaireStartDateTimePicker.data("DateTimePicker").minDate(programQuestionnaireLatestEndDate);
    //    $programQuestionnaireStartDateTimePicker.data("DateTimePicker").date(programQuestionnaireLatestEndDate);
    //});

    //$questionnaireModal.on('hide.bs.modal', function(event) {
    //    if (editClicked) {
    //        $('tbody#added_programQuestionnaires tr.editing').toggleClass('editing', false);
    //        editClicked = false;
    //    }
    //});
    //
    //$(document).on('click', 'tbody#added_programQuestionnaires tr td div button.delete', function() {
    //    var $deleteBtn = $(this);
    //    var program_questionnaire_id = $deleteBtn.data('program-questionnaire-id');
    //
    //    if (program_questionnaire_id != null) {
    //        var input_json = {
    //            "id": program_questionnaire_id,
    //            "deleted": true
    //        };
    //        console.log(input_json);
    //        $('tbody#added_programQuestionnaires').append(
    //            "<input type='hidden' name='programQuestionnaires[]' value='" + JSON.stringify(input_json) + "'>"
    //        )
    //    }
    //
    //    $deleteBtn.closest('tr').remove();
    //});

    //$programQuestionnaireStartDateTimePicker.datetimepicker();
    //$programQuestionnaireEndDateTimePicker.datetimepicker({
    //    useCurrent: false
    //});
    //$programQuestionnaireStartDateTimePicker.on('dp.change', function(e) {
    //    $programQuestionnaireEndDateTimePicker.data("DateTimePicker").minDate(e.date);
    //});
    //$programQuestionnaireEndDateTimePicker.on('dp.change', function(e) {
    //    $programQuestionnaireStartDateTimePicker.data("DateTimePicker").maxDate(e.date);
    //});
}());