/**
 * Created by weiyuanpan on 2/18/16.
 */
(function() {
    $(function() {
        var $reportExamineeModal = $('#reportExamineeModal');
        var $reportExamineeTimePicker = $reportExamineeModal.find('#outputSettingDateTimePicker');
        var diagnosisCourse1stVal = $reportExamineeModal.find('select[name=diagnosisCourse] option').val();
        var programSelection =  $reportExamineeModal.find('select[name=programSelection]');
        var diagnosisProgramSelection =  $reportExamineeModal.find('select[name=diagnosisProgramSelection]');
        var reportFormatSelection =  $reportExamineeModal.find('select[name=reportFormatSelection]');
        var reportFormat1stVal = $reportExamineeModal.find('select[name=reportFormatSelection] option').val();

        var outputSettingTimeReferenceERadio = $reportExamineeModal.find('.radio');

        var currentExamineeId;
        var currentExamineeName;
        var currentProgramDiagnosisProgram;
        var currentMethod = undefined;
        var currentExamineeHasAnswer;
        var currentExaminees;
        var currentDiagnosisPrograms;

        var $reportExamineeModalTable = $reportExamineeModal.find('table');

        initDateTimePicker();
        initModal();

        function initDateTimePicker() {
            $reportExamineeTimePicker.datetimepicker();
        }

        function initModal() {
            initButtonClickEvent();
            initSettingChangeEvent();

            $reportExamineeModal.on('show.bs.modal', function(event) {
                var button = $(event.relatedTarget);
                currentExamineeId = button.data('examinee-id');
                currentExamineeName = button.data('examinee-name');
                queryCurrentProgramDiagnosisProgram();
            });

            $reportExamineeModal.find('.modal_confirm').on('click', function() {
                if (!currentExamineeHasAnswer) {
                    alertModal.show('選択した診断にまだ回答していません');
                    return;
                }

                var examinees = collectCheckedExaminees();
                var diagnosisPrograms = collectCheckedDiagnosisPrograms(examinees);
                queryReportPdf(examinees, diagnosisPrograms);
                $reportExamineeModal.modal('hide');
            });

            $reportExamineeModal.on('hide.bs.modal', function(event) {
                currentMethod = undefined;
                appUtil.bt.resetTable($reportExamineeModalTable, ['resetSearch', 'removeAll']);
            });
        }

        /*1*/

        function initButtonClickEvent() {
            $reportExamineeModal.find('.modal_csv_result').on('click', function() {
                if (!currentExamineeHasAnswer) {
                    alertModal.show('選択した診断にまだ回答していません');
                    return;
                }

                var examinees = collectCheckedExaminees();
                var diagnosisPrograms = collectCheckedDiagnosisPrograms(examinees);
                queryCsvResult(examinees, diagnosisPrograms);
                $reportExamineeModal.modal('hide');
            });
        }

        function initSettingChangeEvent() {
            outputSettingTimeReferenceERadio.find('input[name=outputSettingTimeReferenceE]').change(function() {
                //console.log('change: ' + $(this).val());
                currentMethod = $(this).val();
                resetExaminees();
            });

            $reportExamineeTimePicker.on('dp.change', function(e) {
                if (currentMethod == 'useDate') {
                    resetExaminees();
                }
            });

            $reportExamineeModal.find('select[name=diagnosisCourse]').change(function() {
                if (currentMethod == 'useDate') {
                    resetExaminees();
                }
            });

            programSelection.change(function() {
                updateSelectableDiagnosisPrograms();
                if (currentMethod == 'useDiagnosisProgram') {
                    resetExaminees();
                }
            });

            diagnosisProgramSelection.change(function() {
                if (currentMethod == 'useDiagnosisProgram') {
                    resetExaminees();
                }
            });
        }

        function queryCurrentProgramDiagnosisProgram() {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/report/examinee/" + currentExamineeId + "/diagnosisPrograms"
                })
                .done(function(data) {
                    //console.log(data);

                    initUseDateSettingArea();
                    initUseDiagnosisProgramSettingArea(data);
                    initReportFormatSettingArea();
                    setDefaultCurrentMethod();
                    resetExaminees();
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        function collectCheckedExaminees() {
            appUtil.bt.resetTable($reportExamineeModalTable, ['resetSearch']);
            return appUtil.bt.getSelectedRowsIds($reportExamineeModalTable, 'id');
        }

        function collectCheckedDiagnosisPrograms(examinees) {
            var diagnosis_programs = {};
            diagnosis_programs[currentExamineeId] = {};
            diagnosis_programs[currentExamineeId].id = currentDiagnosisPrograms[currentExamineeId]['id'];

            examinees.forEach(function (e, i, a) {
                diagnosis_programs[e] = {};
                diagnosis_programs[e].id = currentDiagnosisPrograms[e]['id'];
            });

            return diagnosis_programs;
        }

        function queryReportPdf(examinees, diagnosisPrograms) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/report/examinee/" + currentExamineeId,
                    type: 'POST',
                    data: { 'examinees': examinees, 'diagnosisPrograms': diagnosisPrograms, 'reportFormat': reportFormatSelection.val() }
                })
                .done(function(data) {
                    var componets = [
                        {'type': 'link', 'url': appUtil.domain + '/pdf/' + data, 'message': 'PDFを開く'},
                        {
                            'type': 'func',
                            'function':
                            "(function(){" +
                                "var link = document.createElement('a');" +
                                "link.href = '" + appUtil.domain + '/pdf/' + data + "';" +
                                "link.download = '" + currentExamineeName + ".pdf';" +
                                "link.click();" +
                            "})();",
                            'message': 'PDFをダウンロード'
                        }
                    ];
                    buttonModal.show(componets);

                    //console.log(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*2*/

        function queryCsvResult(examinees, diagnosisPrograms) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/download/result/" + currentExamineeId,
                    type: 'POST',
                    data: { 'examinees': examinees, 'diagnosisPrograms': diagnosisPrograms }
                })
                .done(function(data) {
                    if (data['hasError']) {
                        alertModal.show('Error');
                        return;
                    }

                    var link = document.createElement('a');
                    link.href = appUtil.domain + '/csv/' + data['filename'];
                    //link.download = 'file.csv';
                    link.click();

                    //console.log(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        function resetExaminees() {
            appUtil.bt.resetTable($reportExamineeModalTable, ['resetSearch', 'removeAll']);
            var timeRef = getTimeRef();
            queryExaminees(timeRef);
        }

        function updateSelectableDiagnosisPrograms() {
            diagnosisProgramSelection.html('');
            var diagnosisPrograms = currentProgramDiagnosisProgram[programSelection.val()]['diagnosis_programs'];
            diagnosisPrograms.forEach(function (e, i, a) {
                diagnosisProgramSelection.append(
                    '<option value="' + e['id'] + '">' + e['name'] + '</option>'
                );
            });
        }

        function initUseDateSettingArea() {
            $reportExamineeTimePicker.data("DateTimePicker").date(moment());
            $reportExamineeModal.find('select[name=diagnosisCourse]').val(diagnosisCourse1stVal);
        }

        function initUseDiagnosisProgramSettingArea(data) {
            programSelection.html('');
            currentProgramDiagnosisProgram = data;
            currentProgramDiagnosisProgram.forEach(function(e, i, a) {
                programSelection.append(
                    '<option value="' + i + '">' + e['name'] + '</option>'
                )
            });

            diagnosisProgramSelection.html('');
            var diagnosisPrograms = currentProgramDiagnosisProgram[programSelection.val()]['diagnosis_programs'];
            diagnosisPrograms.forEach(function (e, i, a) {
                diagnosisProgramSelection.append(
                    '<option value="' + e['id'] + '">' + e['name'] + '</option>'
                );
            });
        }

        function initReportFormatSettingArea() {
            reportFormatSelection.val(reportFormat1stVal);
        }

        function setDefaultCurrentMethod() {
            outputSettingTimeReferenceERadio.find('input[name=outputSettingTimeReferenceE]').val(['useDate']);
            currentMethod = 'useDate';
        }

        /*3*/

        function getTimeRef() {
            //console.log(currentMethod);

            var timeRef = {};
            if (currentMethod == 'useDate') {
                timeRef.diagnosis_id = $reportExamineeModal.find('select[name=diagnosisCourse]').val();
                timeRef.date = $reportExamineeTimePicker.data("DateTimePicker").date().format("YYYY-MM-DD HH:mm:ss");
            } else if (currentMethod == 'useDiagnosisProgram'){
                timeRef.diagnosis_program_id = diagnosisProgramSelection.val();
            }

            //console.log(timeRef);
            return timeRef;
        }

        function queryExaminees(timeRef) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/report/examinees",
                    data: { 'method': currentMethod, 'timeRef': timeRef }
                })
                .done(function(data) {
                    //console.log(data);

                    currentExamineeHasAnswer = false;
                    currentExaminees = data['examinees'];
                    currentDiagnosisPrograms = data['diagnosis_programs'];

                    if (currentExaminees.length === 0) {
                        alertModal.show('利用できる受検者のデータがありません');
                        return;
                    }

                    checkCurrentExamineeAnswerExistence();

                    if (!currentExamineeHasAnswer) {
                        alertModal.show('この受検者の回答がありません');
                        return;
                    }

                    insertExaminees(currentExaminees);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*4*/

        function checkCurrentExamineeAnswerExistence() {
            for (var i = 0; i < currentExaminees.length; i++) {
                if (currentExaminees[i]['id'] == currentExamineeId) {
                    currentExamineeHasAnswer = true;
                    currentExaminees.splice(i, 1);
                    break;
                }
            }
        }

        function insertExaminees(data) {
            var rows = prepareTableRows(data);
            appUtil.bt.reLoadRows($reportExamineeModalTable, rows);
        }

        /*5*/

        function prepareTableRows(data) {
            var rows = [];
            //TODO add more info(current examinee, どのプログラムが最も近いかなど )
            data.forEach(function(e, i, a) {
                var gender = e['gender'] == 'male' ? '男性' : e['gender'] == 'female' ? '女性' : 'ー';
                var entrance_date = e['entrance_date'] === '1900-01-01' ? 'ー' : e['entrance_date'];
                var age = e['birthday'] === '1900-01-01' ? 'ー' : e['age'];
                rows.push({
                    inner_id: e['inner_id'],
                    name: e['name'],
                    email: e['email'],
                    gender: gender,
                    age: age,
                    entrance_date: entrance_date,
                    department: e['department'],
                    position_id: e['position']['position'],
                    last_diagnosis_answer_date: e['last_diagnosis_answer_date'],
                    id: e['id']
                });
            });
            return rows;
        }
    });
}());