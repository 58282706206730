/**
 * Created by weiyuanpan on 2/18/16.
 */
(function() {
    $(function() {
        var $reportDiagnosisProgramModal = $('#reportDiagnosisProgramModal');
        var reportFormatSelection =  $reportDiagnosisProgramModal.find('select[name=reportFormatSelection]');
        var reportFormat1stVal = $reportDiagnosisProgramModal.find('select[name=reportFormatSelection] option').val();

        var currentDiagnosisProgramId;
        var currentExamineeId;
        var currentExamineeName;
        var currentExamineeHasAnswer;

        var $reportDiagnosisProgramModalTable = $reportDiagnosisProgramModal.find('table');

        initModal();

        function initModal() {
            initButtonClickEvent();

            $reportDiagnosisProgramModal.on('show.bs.modal', function(event) {
                var button = $(event.relatedTarget);
                currentExamineeId = button.data('examinee-id');
                currentExamineeName = button.data('examinee-name');

                initUseDiagnosisProgramSettingArea(button.data('diagnosis-program-name'), button.data('diagnosis-program-id'));
                initReportFormatSettingArea();
                resetExaminees();
            });

            $reportDiagnosisProgramModal.find('.modal_confirm').on('click', function() {
                if (!currentExamineeHasAnswer) {
                    alertModal.show('選択した診断にまだ回答していません');
                    return;
                }

                var examinees = collectCheckedExaminees();
                queryReportPdf(examinees);
                $reportDiagnosisProgramModal.modal('hide');
            });
        }

        /*1*/

        function initButtonClickEvent() {
            $reportDiagnosisProgramModal.find('.modal_csv_result').on('click', function() {
                if (!currentExamineeHasAnswer) {
                    alertModal.show('選択した診断にまだ回答していません');
                    return;
                }

                var examinees = collectCheckedExaminees();
                queryCsvResult(examinees);
                $reportDiagnosisProgramModal.modal('hide');
            });
        }

        function initUseDiagnosisProgramSettingArea(diagnosisProgramName, diagnosisProgramId) {
            currentDiagnosisProgramId = diagnosisProgramId;
            $reportDiagnosisProgramModal.find('#diagnosisProgramSelection option').text(diagnosisProgramName);
            $reportDiagnosisProgramModal.find('#diagnosisProgramSelection option').val(currentDiagnosisProgramId);
        }

        function initReportFormatSettingArea() {
            reportFormatSelection.val(reportFormat1stVal);
        }

        function resetExaminees() {
            appUtil.bt.resetTable($reportDiagnosisProgramModalTable, ['resetSearch', 'removeAll']);
            queryExaminees();
        }

        function collectCheckedExaminees() {
            appUtil.bt.resetTable($reportDiagnosisProgramModalTable, ['resetSearch']);
            return appUtil.bt.getSelectedRowsIds($reportDiagnosisProgramModalTable, 'id');
        }

        function queryReportPdf(examinees) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/report/examinee/" + currentExamineeId + '/' + currentDiagnosisProgramId,
                    type: 'POST',
                    data: { 'examinees': examinees, 'reportFormat': reportFormatSelection.val() }
                })
                .done(function(data) {
                    var componets = [
                        {'type': 'link', 'url': appUtil.domain + '/pdf/' + data, 'message': 'PDFを開く'},
                        {
                            'type': 'func',
                            'function':
                            "(function(){" +
                                "var link = document.createElement('a');" +
                                "link.href = '" + appUtil.domain + '/pdf/' + data + "';" +
                                "link.download = '" + currentExamineeName + ".pdf';" +
                                "link.click();" +
                            "})();",
                            'message': 'PDFをダウンロード'
                        }
                    ];
                    buttonModal.show(componets);

                    //console.log(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*2*/

        function queryCsvResult(examinees) {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/download/result/" + currentExamineeId + '/' + currentDiagnosisProgramId,
                    type: 'POST',
                    data: { 'examinees': examinees }
                })
                .done(function(data) {
                    if (data['hasError']) {
                        alertModal.show('Error');
                        return;
                    }

                    var link = document.createElement('a');
                    link.href = appUtil.domain + '/csv/' + data['filename'];
                    //link.download = 'file.csv';
                    link.click();

                    //console.log(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        function queryExaminees() {
            loadingModal.start();
            $.ajax({
                    url: appUtil.domain + "/api/report/examinees/" + currentDiagnosisProgramId
                })
                .done(function(data) {
                    //console.log(data);

                    currentExamineeHasAnswer = false;

                    if (data.length === 0) {
                        alertModal.show('利用できる受検者のデータがありません');
                        return;
                    }

                    checkCurrentExamineeAnswerExistence(data);

                    if (!currentExamineeHasAnswer) {
                        alertModal.show('この受検者の回答がありません');
                        return;
                    }

                    insertExaminees(data);
                })
                .fail(function(data) {
                    alertModal.show('通信エラー');
                    //console.log(data);
                })
                .always(function() {
                    loadingModal.stop();
                });
        }

        /*3*/

        function checkCurrentExamineeAnswerExistence(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i]['id'] == currentExamineeId) {
                    currentExamineeHasAnswer = true;
                    data.splice(i, 1);
                    break;
                }
            }
        }

        function insertExaminees(data) {
            var rows = prepareTableRows(data);
            appUtil.bt.reLoadRows($reportDiagnosisProgramModalTable, rows);
        }

        /*4*/

        function prepareTableRows(data) {
            var rows = [];
            //TODO add more info(current examinee, どのプログラムが最も近いかなど )
            data.forEach(function(e, i, a) {
                var gender = e['gender'] == 'male' ? '男性' : e['gender'] == 'female' ? '女性' : 'ー';
                var entrance_date = e['entrance_date'] === '1900-01-01' ? 'ー' : e['entrance_date'];
                var age = e['birthday'] === '1900-01-01' ? 'ー' : e['age'];
                rows.push({
                    inner_id: e['inner_id'],
                    name: e['name'],
                    email: e['email'],
                    gender: gender,
                    age: age,
                    entrance_date: entrance_date,
                    department: e['department'],
                    position_id: e['position']['position'],
                    last_diagnosis_answer_date: e['last_diagnosis_answer_date'],
                    id: e['id']
                });
            });
            return rows;
        }
    });
}());